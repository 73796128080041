import React from "react";
import profilePic from "../../images/profile_pic.jpg";
import { resumeData } from "../../resumeData";
import "./About.css";

export const About = () => {
  const scrollToTechSkillsSection = () => {
    document
      .querySelector("#skills-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  const printBio = () => {
    const bio = resumeData.bioDescription.split("<skillsLink>");
    // return <p className='about-text' className='about-text'>{bio[0]}<span onClick={scrollToTechSkillsSection} className='tech-skills-link'>see complete list</span>{bio[1]}</p>
  };

  return (
    <div>
      <div className="about-clip-path-top"></div>
      <section id="ourstory-section" className="about-outer-container">
        <div className="profile-pic-container">
          {/* <img
            alt="zacharys profile"
            src={profilePic}
            className="profile-pic"
          /> */}
        </div>
        <div className="about-info-container" style={{ color: "white" }}>
          {/* <h2 className='about-text'>WEBSITE COMING SOON!</h2> */}
          {printBio()}
          <p className='about-text'>
            Vinh &amp; Seema first became friends when they were assigned to sit
            next to each other during their senior year of high school in 2011.
            Seema would give Vinh answers for homework while Vinh would buy
            cookies for Seema. It was a perfect deal. This then led to going to
            senior prom together and the rest is history.
          </p>
          <p className='about-text'>
            A few of Vinh and Seema’s favorite memories: Hiking in the
            Adirondacks, glacier trekking in Alaska, cycling, playing
            pickleball, and exploring varieties of food together.
          </p>
          <p className='about-text'>
            Vinh’s proposal was unique because he did not tell anyone about it.
            Thankfully, Vinh’s sister captured the moment! He proposed to Seema
            on top of Old Rag Mountain in Virginia in September 2020 . Vinh
            almost dropped the ring because he was so nervous – yikes!
          </p>
          <p className='about-text'>
            Vinh and Seema are looking forward to becoming married and starting
            a new chapter in their lives.
          </p>
          {/* <div className='contact-info-container'>
            <h2 className='about-text'>Contact Details</h2>
            <p className='about-text' className='about-text'>Zachary Nemeroff</p>
            <p className='about-text' className='about-text'>Denver, CO</p>
            <a rel='noopener noreferrer' aria-label='send email' href='mailto: ZaNemeroff@gmail.com' target='_blank'><p className='about-text' className='about-text email-text'>ZaNemeroff@gmail.com</p></a>
            <a rel='noopener noreferrer' href={resumeData.resumeURL} target='_blank'><button tabIndex='-1' aria-label='view resume as PDF' className='resume-button'>resume.pdf</button></a>
          </div> */}
        </div>
      </section>
    </div>
  );
};
