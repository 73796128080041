import React from "react";
import { GameBoard } from "../GameBoard/GameBoard";
import "./SimonGame.css";

export const SimonGame = () => {
  return (
    <div>
      <section id="simon-section" className="simon-game-outer-container">
        <h2 className="simon-title">Mr. Simon Game</h2>

        <GameBoard />
        <br />
        <br />

        <h6 className="rules-text">
          Like the site? {' '}
          <a
            className="rules-text"
            href="https://zacharynemeroff.com/"
            target="_blank"
          >
            https://zacharynemeroff.com/
          </a>
        </h6>
      </section>
      <div className="simon-clip-path-bottom"></div>
    </div>
  );
};
