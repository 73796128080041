import React, { useState, useEffect, useRef } from "react";
import "./Nav.css";

export const Nav = () => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const [animateClass, setAnimateClass] = useState("hidden");
  const [hamburgerBtnTurnLeft, setHamburgerBtnTurnLeft] = useState("");
  const mobileNavRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (mobileNavVisible && !mobileNavRef.current.contains(event.target)) {
      onHamburgerClick();
    }
  };

  const performSmoothScroll = (sectionName) => {
    // console.log(`#${sectionName}-section`)
    document
      .querySelector(`#${sectionName}-section`)
      .scrollIntoView({ behavior: "smooth" });
    onHamburgerClick();
  };

  const openSeatingChart = () => {
    window.open(
      "https://raja-nguyen-wedding.s3.amazonaws.com/SeatingArangments_final-compressed.pdf"
    );
  }

  const openRSVP = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdUtxPD3cKYr0vFAiwk4MJKkodZNAo-zmY5goL75FYS4C25Ww/viewform?usp=sf_link"
    );
  };

  const printNavButtons = () => {
    //home, our story, details, rsvp,
    const navHeadings = [
      "home",
      "Our Story",
      "itinerary",
      "Wedding Gifts",
      "RSVP",
      "Seating Chart",
    ];
    const navButtons = navHeadings.map((label) => {
      let heading = label.replace(/\s/g, "").toLowerCase();
      if (label == "RSVP") {
        return (
          <button
            tabIndex="0"
            aria-label={`${heading} section`}
            key={`${heading}`}
            onClick={() => openRSVP()}
            className="nav-buttons"
          >{`${label}`}</button>
        );
      }
      console.log(label)

      if (label == "Seating Chart") {
        return (
          <button
            tabIndex="0"
            aria-label={`${heading} section`}
            key={`${heading}`}
            onClick={() => openSeatingChart()}
            className="nav-buttons"
          >{`${label}`}</button>
        );
      }
      return (
        <button
          tabIndex="0"
          aria-label={`${heading} section`}
          key={`${heading}`}
          onClick={() => performSmoothScroll(`${heading}`)}
          className="nav-buttons"
        >{`${label}`}</button>
      );
    });
    return navButtons;
  };

  const onHamburgerClick = () => {
    if (mobileNavVisible) {
      setHamburgerBtnTurnLeft("hamburger-button-turn-left");
      setMobileNavVisible(false);
      setAnimateClass("mobile-menu animate-slide-out");
      setTimeout(() => {
        setAnimateClass("hidden");
      }, 800);
    } else {
      setMobileNavVisible(true);
    }
  };

  return (
    <nav>
      <div ref={mobileNavRef} className="mobile-nav-container">
        <button
          onClick={onHamburgerClick}
          aria-label="toggle menu visibility"
          className={
            !mobileNavVisible
              ? `hamburger-button ${hamburgerBtnTurnLeft}`
              : "hamburger-button-active"
          }
        ></button>
        <div
          className={
            mobileNavVisible
              ? "mobile-menu animate-slide-in"
              : `${animateClass}`
          }
        >
          {printNavButtons()}
        </div>
      </div>
      <div className="full-nav-container">{printNavButtons()}</div>
    </nav>
  );
};
