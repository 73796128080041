export const resumeData = {
  resumeURL: "https://zanemeroff.github.io/resume_host",
  turingAlumniURL: "https://alumni.turing.io/alumni/zachary-nemeroff",
  gitHubURL: "https://github.com/ZaneMeroff",
  twitterURL: "https://twitter.com/zanemeroff",
  linkedInURL: "https://www.linkedin.com/in/zachary-nemeroff",
  bioDescription: "WEBSITE COMING SOON",
  education: {
    school: "Turing School of Software & Design",
    position: "Graduate Front End Web Development Program",
    time: "September 2019 - April 2020",
    description: [
      "Built 14 unique web applications from scratch with vanilla JS, React, & React Native",
      "Created thorough unit testing suites for every application",
      "Programmed for team projects of four people, paired projects, and solo projects",
      "Focused on JavaScript fundamentals for 12 weeks",
      "Completed 1,200 hours building applications with front end and back end technologies",
      "Dedicated 600 hours working strictly with React, React Native, Router, and Redux",
      "Continue to work 1:1 with enrolled students as a mentor to provide technical tutoring, professional guidance, and support",
    ],
  },
  jobs: [
    {
      name: "William F. Bolger Center",
      position: "9600 Newbridge Dr, Potomac, MD 20854\n",
      time: "November 19th, 2022",
      description: [
        "Baraat outside of the Osgood Building: 12:00PM - 12:45PM",
        "Ceremony in The Benjamin Franklin Hall: 1:00PM - 3:00PM",
        "Hors d'oeuvres & Cocktails: 3:00PM - 4:30PM",
      ],
    },
    {
      name: "China Garden Han Gong",
      position: "11333 Woodglen Dr, Rockville, MD 20852",
      time: "November 19th, 2022 5:30 PM",
      description: [
        // 'Doors Open at 5:30 PM'
        // 'Dinner is Served at 6:00PM'
      ],
    },
    {
      name: "Canopy by Hilton Washington DC Bethesda",
      position: "940 Rose Ave, North Bethesda, MD 20852",
      time: "Make Reservations with our block rate:",
      link: "https://bit.ly/3yJ56J8",
      description: [
        //  'Reservations using our link will be made at our block rate'
      ],
    },
  ],
  techSkillz: [
    "JavaScript",
    "TypeScript",
    "HTML5",
    "CSS3",
    "Vue.js",
    "Vuex",
    "Vue Router",
    "React",
    "React Native",
    "React Router",
    "Redux",
    "Node.js",
    "Express",
    "Electron",
    "Elastic Beanstalk",
    "Docker",
    "D3.js",
    "Photoshop",
    "jQuery",
    "TravisCI",
    "CircleCI",
    "Puppeteer",
    "Cypress",
    "Quasar",
    "Stylus",
    "MD Bootstrap",
    "SCSS / SASS",
    "Jest / Enzyme",
    "Mocha / Chai",
    "NPM / Yarn",
    "Git / GitHub",
    "Bitbucket",
  ],
};

export const giftData = {
  resumeURL: "https://zanemeroff.github.io/resume_host",
  turingAlumniURL: "https://alumni.turing.io/alumni/zachary-nemeroff",
  gitHubURL: "https://github.com/ZaneMeroff",
  twitterURL: "https://twitter.com/zanemeroff",
  linkedInURL: "https://www.linkedin.com/in/zachary-nemeroff",
  bioDescription: "WEBSITE COMING SOON",
  education: {
    school: "Turing School of Software & Design",
    position: "Graduate Front End Web Development Program",
    time: "September 2019 - April 2020",
    description: [
      "Built 14 unique web applications from scratch with vanilla JS, React, & React Native",
      "Created thorough unit testing suites for every application",
      "Programmed for team projects of four people, paired projects, and solo projects",
      "Focused on JavaScript fundamentals for 12 weeks",
      "Completed 1,200 hours building applications with front end and back end technologies",
      "Dedicated 600 hours working strictly with React, React Native, Router, and Redux",
      "Continue to work 1:1 with enrolled students as a mentor to provide technical tutoring, professional guidance, and support",
    ],
  },
  jobs: [
    {
      name: "Honeymoon Fund",
      time: "7813 Patterson Way, Hanover MD 21076\n",
      position: "We will have no more money after this wedding to go on a honeymoon.",
      extra: 'Please send us money in the form of a wedding gift to the following address!',
      description: [
        // 'Please '
      ],
    },
    {
      name: "Cat Food Fund",
      time: "Venmo: Vinh-Nguyen-13",
      extra: 'Please send us money in the form of a Cat Food Fund to the following venmo!',
      position: "Our cat will be very hungry because we cannot afford wet canned food.",
      description: [
        // 'Please '
      ],
    },
    {
      name: "Bicycle Fund",
      time: "Paypal: vinni.nguyen5@gmail.com",
      position: "We had to pawn our cars to afford this wedding. Our only forms of transportation are bicycles",
      extra: 'Please send us money in the form of a Bicycle Purchasing and Maintenance Fund to the following paypal!',
      description: [
        // 'Please '
      ],
    },
  ],
  techSkillz: [
    "JavaScript",
    "TypeScript",
    "HTML5",
    "CSS3",
    "Vue.js",
    "Vuex",
    "Vue Router",
    "React",
    "React Native",
    "React Router",
    "Redux",
    "Node.js",
    "Express",
    "Electron",
    "Elastic Beanstalk",
    "Docker",
    "D3.js",
    "Photoshop",
    "jQuery",
    "TravisCI",
    "CircleCI",
    "Puppeteer",
    "Cypress",
    "Quasar",
    "Stylus",
    "MD Bootstrap",
    "SCSS / SASS",
    "Jest / Enzyme",
    "Mocha / Chai",
    "NPM / Yarn",
    "Git / GitHub",
    "Bitbucket",
  ],
};
